@use '../helpers/mixins' as *;
@use '../helpers/vars' as *;
// @use './cuboid-3d.scss' as *;

.header {
    // padding-top: calc((100vh - #{size(550)}) / 2);
    // --pt: Min(150px, 20vh);
    --pt: 15vh;
    // --ht: Min(150px, 10vh);
    --ht: 20vh;
    padding-top: var(--pt);
    position: relative;
    display: grid;

    .h1-wrap {
        position: sticky;
        // top: calc((100vh - #{size(550)}) / 2);
        top: var(--ht);
        grid-area: 1 / 1;
        align-self: start;
        padding-bottom: 50px;
    }
    .computer-wrap {
        grid-area: 2 / 1;
        position: sticky;
        align-self: start;
        // top: Max(50%, var(--top-height) * 1.1);
        top: Max(calc(var(--ht) + #{size(280)}), var(--top-height) * 1.1);
        @include s400 {
            top: Max(calc(var(--ht) + #{size(340)}), var(--top-height) * 1.1);
        }
        @include s480 {
            top: Max(calc(var(--ht) + #{size(420)}), var(--top-height) * 1.1);
        }
    }

    .spacer {
        grid-row: 2;
        grid-column: 1;
        height: 100vh;
    }

    @include md {
        .h1-wrap {
            padding-bottom: 80px;
        }
    }

    @include lg {
        // padding-top: 200px;
        --pt: 200px;
        --ht: 150px;
        .computer-wrap {
            top: Max(50%, var(--top-height) * 1.1);
        }
    }

    --size: Min(47vw, 90vh, 680px);
    --scene-height: calc(var(--size) * 1.3);

    --top-height: var(--size);

    --perspective: 3000;

    --plane-height: var(--size);
    --plane-width: var(--top-width);

    --top-x: 0;
    --top-y: calc(var(--top-height) * -1);
    --top-z: 0;

    --top-width: calc(var(--top-height) * 1.48);
    --top-depth: calc(var(--top-height) * 0.021);
    --top-origin-x: 0%;
    --top-origin-y: 100%;
    --top-origin-z: calc(var(--top-depth) / 2);

    --bot-x: 0;
    --bot-y: 0;
    --bot-z: calc(var(--bot-depth) / 2);
    --bot-depth: calc(var(--bot-width) * 0.675);
    --bot-height: calc(var(--bot-width) * 0.032);
    --bot-width: var(--top-width);
}

.computer {
    height: 30px;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    z-index: 99;

    .scene {
        perspective-origin: 50% 0%;
        position: relative;
        transform-style: preserve-3d;
        perspective: calc(var(--perspective, 800) * 1px);
        * {
            transform-style: preserve-3d;
        }
    }

    .plane {
        height: var(--plane-height);
        width: var(--plane-width);
    }
}

.cuboid {
    height: var(--h);
    width: var(--w);
    position: absolute;

    img {
        display: block;
    }

    .cuboid__side {
        position: absolute;
    }
}

.cuboid.bottom {
    --w: var(--bot-width);
    --h: var(--bot-height);
    --d: var(--bot-depth);

    transform: translate3d(var(--bot-x), var(--bot-y), var(--bot-z));

    #laptop-bottom {
        height: var(--h);
        width: var(--w);
        transform: translate3d(0, 0, calc(var(--d) * 0.5));
    }
}

.cuboid.top {
    --w: var(--top-width);
    --h: var(--top-height);
    --d: var(--top-depth);

    transform-origin: var(--top-origin-x) var(--top-origin-y)
        var(--top-origin-z);
    transform: translate3d(var(--top-x), var(--top-y), var(--top-z))
        rotateX(0deg);

    #laptop-screen {
        height: var(--h);
        width: var(--w);
        transform: translate3d(0, 0, calc(var(--d) * 0.5));
        .img-container {
            position: absolute;
            width: 90%;
            height: 86%;
            top: 7%;
            left: 5%;
            border-radius: 3px;
            transform: translate3d(0, 0, 2px);
            overflow: hidden;
        }

        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
            transform: translate3d(0, 0, 1px);
            position: relative;
        }
    }

    #laptop-top {
        height: var(--d);
        width: var(--w);
        left: 50%;
        top: 50%;
        transform-origin: 0% 100%;
        transform: translate(-50%, -50%)
            translate3d(
                0,
                calc(var(--h) * -0.5 - (var(--d) / 2)),
                calc(var(--d) / 2)
            )
            rotateX(90deg);

        img {
            width: 100%;
            height: 100%;
            position: absolute;
            transform-origin: 50% 100%;
            transform: rotateX(40deg);
            width: 99.5%;
            height: 100%;
            left: 0.25%;
        }
    }
}
