.media {
    position: relative;
    width: 100%;
    --ratio: 100%;
    .media-inner {
        position: relative;
        width: 100%;
        padding-bottom: var(--ratio);
        height: 0;
        overflow: hidden;
        img,
        video {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}
