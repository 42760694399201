@use 'sass:math';
@use './vars' as *;
@forward './media';

$list-of-text-inputs: (
    "[type='color']",
    "[type='date']",
    "[type='datetime']",
    "[type='datetime-local']",
    "[type='email']",
    "[type='month']",
    "[type='number']",
    "[type='password']",
    "[type='search']",
    "[type='tel']",
    "[type='text']",
    "[type='time']",
    "[type='url']",
    "[type='week']",
    'input:not([type])',
    'textarea'
) !default;

@mixin all-text-inputs($pseudo: null) {
    $list: 'hover', 'focus', 'active', 'invalid', 'required', 'disabled';
    @if not $pseudo {
        #{$list-of-text-inputs} {
            @content;
        }
    } @else if index($list, $pseudo) {
        $all-text-inputs: __pseudoSelector($list-of-text-inputs, $pseudo);
        #{$all-text-inputs} {
            @content;
        }
    } @else {
        @error "The argument must be `null` or one of the followings: #{quote($list)}";
    }
}

@mixin debg($color: #f0f) {
    box-shadow: 0 0 0 2px white, 0 0 0px 5px #{$color};
}

@function size($val: 16px) {
    @return (math.div($val, 16) * 1rem);
}

@mixin fluid($prop, $min_vw, $max_vw, $min_val, $max_val) {
    $slope: math.div(($max_val - $min_val), ($max_vw - $min_vw));
    $yInt: size(-$min_vw * $slope + $min_val);

    $min: $min_vw + 'px';
    $max: $max_vw + 'px';

    @media (min-width: $min) {
        #{$prop}: size($min_val);
        #{$prop}: clamp(
            #{size($min_val)},
            #{$yInt} + (#{$slope} * 100vw),
            #{size($max_val)}
        );
    }

    @media (min-width: $max) {
        #{$prop}: size($max_val);
    }
}

@mixin scrollbars() {
    scrollbar-width: thin;
    scrollbar-color: #{transparentize($magenta, 0.5)} #1b1b1b;
    &::-webkit-scrollbar-track {
        background-color: #1b1b1b;
    }
    &::-webkit-scrollbar-thumb {
        background-color: transparentize($magenta, 0.5);
        border-radius: 10px;
        opacity: 0.6;
        // border: 3px solid #{$bg};
        &:hover {
            background-color: transparentize($magenta, 0.1);
        }
    }
    &::-webkit-scrollbar {
        width: 10px;
        display: block;
    }
}
