@use '../helpers/mixins' as *;
@use '../helpers/vars' as *;

.cta {
    .cta-inner {
        display: grid;
        .media.lady {
            max-width: 125px;
            grid-column: 1;
            margin-left: 25%;
            grid-row: 1;
            max-width: 63px;
            margin-bottom: 90px;
        }
        .media.exercise {
            grid-row: 2;
            max-width: 375px;
            --ratio: 62%;
            width: 90%;
            justify-self: end;
            margin-bottom: -25px;
        }
        h2 {
            grid-row: 3;
            max-width: 13ch;
            position: relative;
            z-index: 2;
            margin-bottom: 2rem;
        }
        .text-md {
            margin-bottom: 4rem;
        }

        button {
            grid-row: 5;
        }

        @include sm {
            .media.lady {
                justify-self: end;
                margin-left: 0;
            }
            .media.exercise {
                justify-self: center;
            }
            h2 {
                width: 80%;
                max-width: none;
                margin-inline: auto;
            }
            .text-md {
                width: 80%;
                margin-inline: auto;
                margin-bottom: 5rem;
            }
        }

        @include md {
            grid-template-columns: 20% 1fr 40%;
            h2 {
                margin-left: 1.5%;
                grid-column: 1 / 3;
                grid-row: 3;
                width: 100%;
                max-width: 15ch;
            }
            .media.exercise {
                justify-self: start;
                width: 100%;
                margin-top: 2%;
                margin-bottom: -7%;
                grid-column: 2;
                grid-row: 2;
            }
            .media.lady {
                width: 100%;
                margin-bottom: 0;
                grid-column: 3;
                justify-self: end;
                max-width: 125px;
            }
            .text-md {
                grid-column: 3;
                grid-row: 4;
                margin-top: Max(-11%, -55px);
                margin-bottom: Min(26%, 130px);
                width: 100%;
            }
            button {
                grid-column: 1 / 4;
            }
        }

        @include lg {
            grid-template-columns: 30% 1fr 40%;
        }
    }
}
