@use '../helpers/mixins' as *;
@use '../helpers/vars' as *;

form button[type='submit'] {
    margin-top: 1rem;
}

form label span.req {
    color: var(--pink);
}

.form-message {
    margin-top: 2rem;
}
.form-response {
    margin-top: 1rem;
    text-align: center;
    color: var(--pink);
    &:focus {
        outline: 2px solid var(--blue);
    }
    &:focus:not(:focus-visible) {
        outline: none;
    }
    &:focus-visible {
        outline: 2px solid var(--blue);
    }
}

@include all-text-inputs {
    width: 100%;
    border: 1px solid transparent;
    color: #ebebeb;
    outline: none;
    background-color: #1b1a1a;
    font-weight: 300;
    font-size: size(15);
    line-height: 1.2;
    padding: #{size(16)} #{size(14)};
    color-scheme: dark;
    margin: 0;
    @include sm {
        font-size: size(16);
        padding: #{size(20)} #{size(20)};
    }
    @include lg {
        font-size: size(17);
    }

    &:focus,
    &:active {
        border: none;
        outline: none;
        box-shadow: 0 0 0 2px var(--blue);
    }
}

.magic-label {
    position: relative;
    --label-size: #{size(15)};
    --space: #{size(21)};

    padding-top: calc(var(--label-size) * 1.2);

    label {
        position: absolute;
        padding: 0;
        top: calc(var(--label-size) + var(--space));
        font-size: var(--label-size);
        line-height: 1;
        left: 1rem;
        transform: translate(0, 0);
        transform-origin: left;
        transition: all 0.2s ease;
        white-space: nowrap;
    }

    &.move-label label {
        transform: translate(
                calc(var(--label-size) * -1),
                calc((var(--space) + var(--label-size)) * -1)
            )
            scale(0.9);
        opacity: 0.7;
    }

    &:focus-within {
        label {
            color: var(--pink);
            opacity: 1;
        }
    }

    @include sm {
        --space: #{size(26)};
    }
}
