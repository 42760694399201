@use './helpers/mixins' as *;
@use './helpers/vars' as *;
@use './base/normalize.scss';
@use './base/font-face.scss';
@use './base/fonts.scss';
@use './parts/header.scss';
@use './parts/intro.scss';
@use './parts/footer.scss';
@use './parts/cta.scss';
@use './parts/how.scss';
@use './parts/what.scss';
@use './parts/computer.scss';
@use './parts/modal.scss';
@use './components/media';
@use './components/buttons';
@use './components/forms';

*,
*:before,
*:after {
    box-sizing: border-box;
}

img {
    max-width: 100%;
}

body {
    background-color: #000;
    position: relative;
    --lines: 7;
    --sp: calc(100vw / var(--lines));
    background-image: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0.06) 0px,
        rgba(255, 255, 255, 0.06) 1px,
        transparent 1px,
        transparent 100%
    );
    background-size: var(--sp) 100%;
    background-position: calc(var(--sp) / 2) 0;
    @include md {
        --lines: 9;
    }
    @include lg {
        --lines: 11;
    }
}

body,
html {
    scroll-behavior: smooth;
}

html {
    // @include scrollbars($purple, rgba(255, 255, 255, 0.5));
    @include scrollbars();
}

.mw {
    max-width: 1600px;
    position: relative;
    @include xl {
        margin-left: auto;
        margin-right: auto;
    }
}

.pad {
    padding-left: 9.6%;
    padding-right: 9.6%;
    @include sm {
        padding-left: 7.5%;
        padding-right: 7.5%;
    }
    @include lg {
        padding-left: 6.5%;
        padding-right: 6.5%;
    }
}

.starthidden {
    opacity: 0;

    .no-js & {
        opacity: 1;
    }
}
