$pink: #ff454e;
$blue: #1da5a5;
$purple: #2b154c;
$magenta: #7c197c;

:root {
    --pink: #ff454e;
    --blue: #1da5a5;
    --purple: #2b154c;
    --magenta: #7c197c;
    --primary: var(--pink);
    --secondary: var(--blue);
}
