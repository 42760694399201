@use '../helpers/mixins' as *;
@use '../helpers/vars' as *;

.modal {
    --tDuration: 500ms;
    position: fixed;
    opacity: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    align-items: center;
    justify-content: center;
    transition: all var(--tDuration);

    .modal-inner {
        width: 100%;
        position: relative;
        transform: translateY(200px);
        opacity: 0;
        transition: all var(--tDuration) ease-out;
    }

    &.is-open {
        z-index: 999;
        pointer-events: all;
        opacity: 1;

        .modal-inner {
            transform: none;
            opacity: 1;
        }
    }

    @media (prefers-reduced-motion) {
        transition: opacity 200ms linear;
        .modal-inner {
            transition: none;
        }
    }
}

.modal-inner {
    background-color: #000;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    position: relative;
}

button.modal-close {
    border: 0;
    background: transparent;
    border-radius: 0;
    line-height: 1;
    padding: 0;
    letter-spacing: 0;
    width: size(40);
    height: size(40);
    cursor: pointer;
    &:before {
        content: '\2715';
        font-size: size(20);
    }
    &:hover,
    &:focus {
        color: var(--blue);
        background: transparent;
        box-shadow: none;
    }

    &:focus {
        outline: 2px solid var(--secondary);
    }
    &:focus:not(:focus-visible) {
        outline: none;
    }
    &:focus-visible {
        outline: 2px solid var(--secondary);
    }
}

.modal .modal-content {
    @include scrollbars();
    padding-top: 2.7rem;
    padding-bottom: 3rem;
    height: 100%;
    overflow: auto;
    display: grid;
    grid-template-columns: 1fr min-content;

    .topline {
        display: none;
    }

    .logo-wrap {
        width: size(102);
        grid-area: 1 / 1;
        svg {
            width: 100%;
            height: auto;
        }
        .title-caps {
            display: none;
        }
    }
    button.modal-close {
        grid-area: 1 / 2;
        margin-top: size(-5);
    }

    h1,
    .contact-form,
    .contact-form-desc {
        grid-column: 1 / 3;
    }
    .contact-form {
        width: 100%;
    }

    .contact-form form {
        margin: 0;
        display: flex;
        flex-wrap: wrap;
        .input-wrap {
            flex: 0 0 100%;
            margin-bottom: 0.5rem;
        }
    }

    h1 {
        margin-top: 2rem;
    }

    @include sm {
        padding-top: 80px;
        padding-bottom: 100px;
        padding-left: 7.6%;
        padding-right: 7.6%;
        grid-template-columns: 1fr 40% auto;
        .topline {
            grid-area: 1 / 2;
            display: block;
            align-self: center;
        }
        button.modal-close {
            grid-area: 1 / 3;
            margin: 0;
            align-self: center;
        }
        h1,
        .form,
        .form-info {
            grid-column: 1 / 4;
        }
        .title-caps {
            color: #676767;
            font-size: size(8);
        }
        .logo-wrap {
            width: size(122);
            .title-caps {
                display: block;
                margin-bottom: 0.5rem;
            }
        }
        h1 {
            margin-top: 100px;
        }
        .form-desc {
            margin-bottom: 40px;
        }
        .contact-form form {
            justify-content: space-between;

            .input-wrap {
                flex: 0 0 47.5%;
                margin-bottom: 1rem;
            }
            button[type='submit'] {
                margin-top: 70px;
            }
        }
    }

    @include lg {
        padding-left: 6.4%;
        padding-right: 6.4%;
        grid-template-columns: 1fr size(122);
        grid-template-rows: min-content Min(7.5vh, 67px) min-content min-content;
        .topline {
            grid-area: 1 / 1;
        }
        button.modal-close {
            grid-area: 1 / 2;
            justify-self: end;
        }
        .logo-wrap {
            grid-area: 3 / 2;
            margin-top: size(30);
            .title-caps {
                text-align: right;
            }
        }
        .form-info {
            grid-column: 1 / 3;
            grid-row: 3;
            margin-bottom: 45px;
            margin-bottom: Min(5vh, 45px);
            h1 {
                max-width: 13ch;
                margin: 0;
                display: inline;
            }
            .form-desc {
                margin: 0;
                float: right;
                max-width: 46ch;
                margin-top: 1.5rem;
                font-size: size(16);
            }
        }
        .contact-form {
            grid-row: 4;
            form {
                .input-wrap {
                    flex: 0 0 24%;
                }
                button[type='submit'] {
                    margin-top: Min(8vh, 80px);
                }
            }
        }
    }

    @include xl {
        .form-info {
            .form-desc {
                // max-width: size(588);
                max-width: 52ch;
                margin-top: 3rem;
                font-size: size(17);
            }
        }
    }
}
