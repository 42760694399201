@mixin s400 {
    @media (min-width: 400px) {
        @content;
    }
}
@mixin s480 {
    @media (min-width: 480px) {
        @content;
    }
}

@mixin xxs-down {
    @media (max-width: 575px) {
        @content;
    }
}

@mixin xxs {
    @media (min-width: 576px) {
        @content;
    }
}

@mixin xs {
    @media (min-width: 635px) {
        @content;
    }
}

@mixin xs-down {
    @media (max-width: 634px) {
        @content;
    }
}

@mixin sm {
    @media (min-width: 768px) {
        @content;
    }
}

@mixin sm-down {
    @media (max-width: 767px) {
        @content;
    }
}
@mixin xs-md {
    @media (min-width: 635px) and (max-width: 989px) {
        @content;
    }
}
@mixin sm-md {
    @media (min-width: 768px) and (max-width: 989px) {
        @content;
    }
}
@mixin sm-lg {
    @media (min-width: 768px) and (max-width: 1199px) {
        @content;
    }
}
@mixin md-lg {
    @media (min-width: 990) and (max-width: 1199px) {
        @content;
    }
}

@mixin md {
    @media (min-width: 990px) {
        @content;
    }
}

@mixin md-down {
    @media (max-width: 989px) {
        @content;
    }
}

@mixin lg {
    @media (min-width: 1200px) {
        @content;
    }
}

@mixin lg-down {
    @media (max-width: 1199px) {
        @content;
    }
}

@mixin xl {
    @media (min-width: 1440px) {
        @content;
    }
}

@mixin xxl {
    @media (min-width: 1600px) {
        @content;
    }
}

@mixin s1920 {
    @media (min-width: 1920px) {
        @content;
    }
}
