@use '../helpers/mixins' as *;
@use '../helpers/vars' as *;

.how {
    padding-top: 100px;
    position: relative;

    .how-inner {
        display: grid;
        position: relative;
        z-index: 2;
        h2 {
            grid-row: 2;
            margin-bottom: 60px;
        }
        .media.eyes {
            max-width: 253px;
            grid-row: 3;
            justify-self: center;
            width: 100%;
            --ratio: 37%;
            video {
                width: 110%;
                height: 140%;
                left: -5%;
                top: 0;
            }
        }
        .media.fingers {
            max-width: 63px;
            grid-row: 1;
            width: 30%;
            margin-left: 12%;
            margin-bottom: 50px;
        }
    }

    @include sm {
        .how-inner {
            h2 {
                margin-bottom: 88px;
            }
            .media.fingers {
                grid-row: 1;
                display: block;
                max-width: 87px;
                margin-left: 27%;
                margin-bottom: 70px;
            }
            .media.eyes {
                max-width: 364px;
            }
        }
    }
    @include lg {
        padding-top: 150px;
        .how-inner {
            h2 {
                margin-bottom: 1rem;
            }
            .media.eyes {
                max-width: 252px;
                grid-row: 3;
                justify-self: end;
                width: 50%;
                margin-right: -1%;
                --ratio: 46%;
            }
            .media.fingers {
                max-width: 125px;
                grid-row: 1;
                margin: 0;
                margin-bottom: 1rem;
                width: 20%;
            }
        }
    }
    @include xl {
        .how-inner {
            .media.fingers {
                margin-bottom: -2%;
            }
        }
    }
}

.how h2 {
    display: grid;
    justify-content: center;
    text-align: center;
    img {
        @include fluid(height, 320, 768, 50, 70);
        @include fluid(--bottom, 320, 768, 16, 30);

        display: block;
        margin-inline: auto;
        margin-bottom: calc(var(--bottom) * -1);
        z-index: 1;
    }
    .bs span,
    .nonbs span {
        color: var(--pink);
        font-size: 60%;
        display: inline-block;
        transform: translate(-10%, -40%);
    }
    span {
        line-height: 0.9;
        white-space: nowrap;
    }
    .non {
        @include fluid(font-size, 320, 768, 67, 160);
    }
    .bs {
        @include fluid(font-size, 320, 768, 57, 136);
        letter-spacing: size(-0.5);
        font-weight: 500;
        margin-bottom: 0.5rem;
    }

    .desk {
        display: none;
    }
    .cons,
    .consist {
        @include fluid(font-size, 320, 768, 34, 73);
        letter-spacing: 0;
        line-height: 1.1;
        font-weight: 500;

        @include sm {
            @include fluid(font-size, 768, 1200, 73, 102);
        }
    }

    @include sm {
        @include fluid(--img-width, 768, 1200, 103, 130);
        display: grid;
        grid-template-columns: calc(var(--img-width) - 0.7rem) 1fr;
        width: 100%;
        img {
            width: var(--img-width);
            max-width: none;
            height: auto;
            grid-area: 1 / 1;
            margin-bottom: 0;
            align-self: center;
            margin-right: size(-12);
        }
        .mob {
            display: none;
        }

        .cons {
            grid-row: 2;
            grid-column: 1 / 3;
            justify-self: start;
            text-align: left;
            padding-left: 1.5%;
            line-height: 1;
        }
        .consist {
            grid-row: 3;
            grid-column: 1 / 3;
            justify-self: end;
            text-align: right;
            line-height: 0.9;
            padding-right: 2.5%;
        }
        .nonbs.desk {
            grid-area: 1 / 2;
            display: block;
            @include fluid(font-size, 768, 1200, 73, 102);
            letter-spacing: size(-0.5);
            span {
                font-size: 70%;
                transform: translate(10%, -30%);
                letter-spacing: size(5);
            }
        }
    }

    @include lg {
        grid-template-columns: 10% size(130) 1fr;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        img {
            grid-column: 2;
            width: size(130);
            justify-self: end;
        }

        .nonbs.desk {
            grid-column: 3;
            grid-row: 1;
            justify-self: start;
            text-align: left;
            font-size: size(102);
        }
        .cons {
            grid-column: 1 / 4;
            padding-left: 0;
            line-height: 0.9;
            justify-self: start;
            font-size: size(99);
        }
        .consist {
            font-size: size(91);
            line-height: 0.8;
            grid-row: 3;
            grid-column: 3;
            justify-self: center;
            margin: 0;
        }
    }

    @include xl {
        width: 75%;
        grid-template-columns: 2.5% size(130) 1fr;
    }
}

.how-steps {
    margin-top: 105px;
    margin-bottom: 10px;
    position: relative;
    display: grid;
    .num {
        font-size: size(175);
        line-height: 0.7;
        font-weight: 200;
        letter-spacing: -0.02em;
        transform-style: preserve-3d;
        perspective: 300px;
        display: grid;
        align-items: center;
        grid-template-columns: min-content 1fr;

        .digit {
            grid-row: 1;
            grid-column: 2;
            color: var(--pink);
            display: block;
            justify-self: start;
        }

        &.desk {
            display: none;
        }
        &.mobile {
            margin-bottom: 2rem;
            margin-left: -0.8rem;
        }

        @include sm {
            @include fluid(font-size, 768, 1440, 175, 329);
            margin-bottom: 0;

            &.mobile {
                display: none;
            }
            &.desk {
                display: grid;
            }

            .digit {
                transform: translateY(-100%);
                opacity: 0;
                display: block;
            }
        }
    }
    .title-caps {
        letter-spacing: 0.47em;
        margin-bottom: 1rem;
        margin-bottom: 2rem;
        @include lg {
            margin-bottom: 4rem;
        }
    }
    .step {
        margin-bottom: 95px;
        @include fluid(font-size, 320, 768, 19, 24);
        line-height: 1.5;
        @include sm {
            @include fluid(font-size, 768, 1440, 24, 30);
        }
    }
    @include sm {
        display: grid;
        grid-template-columns: 39% 1fr;
        grid-template-rows: auto 1fr;
        padding-left: 5%;
        padding-right: 5%;
        grid-template-columns: 1fr auto;
        margin-bottom: 80px;
        .step {
            max-width: 18ch;
            min-height: 100vh;
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-bottom: 0;
        }
        .num {
            grid-column: 1;
            grid-row: 1;
            position: sticky;
            top: 0%;
        }
        .step {
            grid-column: 2;
            min-height: 100vh;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }

    @include md {
        padding-left: 8%;
        padding-right: 8%;
    }

    @include lg {
        padding-left: 10%;
        padding-right: 12%;
        margin-top: -100px;
    }
}
