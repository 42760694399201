@font-face {
    font-family: 'galanogrotesque';
    src: url('/src/fonts/galano-grotesque-thin.woff2') format('woff2'),
        url('/src/fonts/galano-grotesque-thin.woff') format('woff');
    font-weight: 200;
}

@font-face {
    font-family: 'galanogrotesque';
    src: url('/src/fonts/galano-grotesque-light.woff2') format('woff2'),
        url('/src/fonts/galano-grotesque-light.woff') format('woff');
    font-weight: 300;
}

@font-face {
    font-family: 'galanogrotesque';
    src: url('/src/fonts/galano-grotesque-reg.woff2') format('woff2'),
        url('/src/fonts/galano-grotesque-reg.woff') format('woff');
    font-weight: 400;
}

@font-face {
    font-family: 'galanogrotesque';
    src: url('/src/fonts/galano-grotesque-med.woff2') format('woff2'),
        url('/src/fonts/galano-grotesque-med.woff') format('woff');
    font-weight: 500;
}

@font-face {
    font-family: 'galanogrotesque';
    src: url('/src/fonts/galano-grotesque-semibold.woff2') format('woff2'),
        url('/src/fonts/galano-grotesque-semibold.woff') format('woff');
    font-weight: 600;
}

@font-face {
    font-family: 'galanogrotesque';
    src: url('/src/fonts/galano-grotesque-bold.woff2') format('woff2'),
        url('/src/fonts/galano-grotesque-bold.woff') format('woff');
    font-weight: 700;
}
