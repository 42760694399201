@use '../helpers/mixins' as *;
@use '../helpers/vars' as *;

.intro {
    padding-bottom: 100px;
    padding-top: 100px;
    @include sm {
        padding-bottom: 140px;
    }
    @include md {
        padding-bottom: 150px;
    }

    .img-bg {
        position: absolute;
        width: 100%;
        height: auto;
        top: 95px;
        left: 0px;
        opacity: 0.5;
        z-index: -1;
    }

    .media {
        opacity: 0;
    }
}

.intro-inner {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: var(--row-top) auto;
    --row-top: #{size(170)};
    h2 {
        position: relative;
        margin-bottom: 0;
        align-self: start;
        max-width: 20ch;
        grid-row: 2 / 4;
        grid-column: 1 / 3;
        margin-bottom: 4.2rem;
    }

    .media {
        &.guy {
            --ratio: 120%;
            @include fluid(max-width, 320, 768, 179, 256);
            justify-self: center;
            grid-row: 1 / 3;
            grid-column: 1 / 3;
            video {
                width: 150%;
                height: 150%;
                // height: 130%;
                left: -18%;
                top: -15%;
            }
        }
        &.dog {
            @include fluid(max-width, 320, 768, 88, 175);
            grid-column: 1;
            grid-row: 4;
        }
        &.thumb {
            @include fluid(max-width, 320, 768, 58, 116);
            justify-self: end;
            align-self: start;
            grid-row: 4;
            grid-column: 2;
        }
        &.frown {
            grid-row: 7;
            grid-column: 1 / 3;
            --ratio: 33%;
            justify-self: center;
            @include fluid(max-width, 320, 768, 257, 474);
            video {
                object-position: 50% top;
            }
        }
    }

    .text-md {
        text-align: center;
        grid-column: 1 / 3;
        margin-top: 3.6rem;
        margin-bottom: 2rem;
        p {
            margin-bottom: 1.4rem;
        }
    }

    @include sm {
        --row-top: #{size(165)};
        h2 {
            max-width: none;
            padding-left: 3.9%;
            margin-bottom: 2.6rem;
        }
        .media.dog {
            align-self: end;
            padding-top: 2.3rem;
            margin-left: 6%;
        }
        .media.thumb {
            margin-right: 20%;
        }
        .text-md {
            margin-top: 4.7rem;
            text-align: left;
            padding-left: 3.5%;
            margin-bottom: 6rem;
        }
    }

    @include md {
        grid-template-columns: 20% 25% 25% 30%;
        grid-template-rows: 150px size(100) auto size(150) auto;
        h2 {
            grid-column: 1 / 4;
            grid-row: 2 / 4;
            padding-left: 0;
            margin: 0;
            max-width: 20ch;
        }
        .text-md {
            grid-row: 2 / 4;
            grid-column: 4;
            margin: 0;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 0;
            max-width: 28ch;
            p {
                margin: 0;
            }
        }
        .media.dog {
            grid-row: 5;
            grid-column: 1;
            align-self: end;
            width: 76%;
            margin: 0;
            padding: 0;
        }
        .media.guy {
            grid-row: 1 / 3;
            grid-column: 2;
        }
        .media.thumb {
            // max-width: 125px;
            justify-self: start;
            grid-row: 5;
            grid-column: 3;
            margin-top: -50px;
            width: 34%;
        }
        .media.frown {
            align-self: center;
            // max-width: 377px;
            margin: 0;
            grid-row: 5;
            grid-column: 4;
        }
    }

    @include lg {
        grid-template-columns: 20% 20% 30% 30%;
    }
}
