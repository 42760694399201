@use '../helpers/mixins' as *;
@use '../helpers/vars' as *;

footer.site-footer {
    padding-bottom: 110px;
    padding-top: 190px;
    background-image: linear-gradient(to bottom, #000, rgba(0, 0, 0, 0) 50%),
        url('/src/img/stars.jpg');
    background-size: cover;
    background-position: bottom center;
    @include sm {
        padding-bottom: 160px;
    }
    @include lg {
        padding-bottom: 200px;
        padding-top: 235px;
    }
    .footer-inner {
        display: grid;
        grid-template-columns: 1fr 17%;
        grid-template-areas:
            'caps img'
            'logo img'
            'tag tag'
            'para para'
            'link link';
        .caps {
            font-size: size(9);
            line-height: 1;
            grid-area: caps;
            align-self: start;
            margin-bottom: 10px;
            @include sm {
                font-size: size(12);
                align-self: center;
                margin-bottom: 0;
            }
            @include lg {
                font-size: size(14);
            }
        }
        a.logo-link {
            grid-area: logo;
            width: 160px;
            height: auto;

            &:hover,
            &:focus {
                svg g {
                    fill: var(--pink);
                }
            }
        }
        .tagline {
            grid-area: tag;
            margin-top: 100px;
            margin-bottom: 2rem;
        }
        .footer-links {
            grid-area: link;
            display: flex;
            justify-content: space-between;
            font-size: size(17);
            a {
                white-space: nowrap;
            }
            @include sm {
                font-size: size(30);
                .num-inline {
                    font-size: size(12);
                    transform: translate(-30%, -110%);
                }
            }
        }
        .footer-para {
            grid-area: para;
            margin-bottom: 90px;
        }
    }

    .interface {
        grid-area: img;
        height: auto;
        opacity: 0.25;
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-between;
        align-items: flex-end;
        img {
            width: 100%;
            max-width: 65px;
            &:nth-child(2) {
                width: 80%;
                max-width: 45px;
            }
        }
        @include sm {
            flex-direction: row;
            align-items: center;
            justify-self: end;
            align-self: center;
            img:first-child {
                margin-right: 1rem;
                height: 12px;
            }
            img:last-child {
                height: 10px;
            }
        }
    }

    @include sm {
        .footer-inner {
            grid-template-columns: 1fr 25% 1fr;
            grid-template-areas:
                'caps logo img'
                'tag tag tag'
                'para para para'
                'link link link';
            .footer-para {
                margin-bottom: 100px;
                // margin-left: 10%;
            }
            .footer-links {
                margin: 0;
                align-self: end;
            }
            .tagline {
                margin-top: 120px;
                margin-bottom: 104px;
            }
        }
    }

    @include lg {
        .footer-inner {
            padding-left: 12%;
            padding-right: 13%;
            grid-template-columns: 43% 25% 1fr;
            grid-template-areas:
                'caps logo img'
                'tag tag tag'
                'link para para';
            .tagline {
                margin-bottom: 10%;
                margin-top: 110px;
            }
            .footer-para {
                margin-left: 23%;
                margin-bottom: 0;
            }
            .footer-links {
                justify-content: start;
                a {
                    margin-right: 4rem;
                }
            }
        }
    }
}
