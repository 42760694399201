@use '../helpers/mixins' as *;
@use '../helpers/vars' as *;

html,
body {
    font-family: galanogrotesque, sans-serif;
    color: #fff;
}

body {
    @include fluid(font-size, 320, 768, 15, 17);
    font-weight: 300;
    line-height: 1.35;
}

:is(h1, h2, h3, h4, h5, h6) {
    margin-bottom: 1rem;
    margin-top: 0;
}

.title-bold {
    font-weight: 600;
    line-height: 0.8;
    text-transform: uppercase;
    letter-spacing: size(-3);
}
.b1 {
    @include fluid(font-size, 320, 768, 67, 160);
    @include sm {
        @include fluid(font-size, 768, 1440, 160, 180);
    }
}

.b2 {
    @include fluid(font-size, 320, 768, 57, 136);
    @include sm {
        @include fluid(font-size, 768, 1440, 136, 180);
    }
}

.title-1,
h1 {
    font-weight: 200;
    line-height: 1.25;
    @include fluid(font-size, 320, 768, 28, 44);
    @include sm {
        line-height: 1.182;
        @include fluid(font-size, 768, 1440, 44, 57);
    }
    @include lg {
        line-height: 1.05;
    }
}

.title-2,
h2 {
    @include fluid(font-size, 320, 768, 22, 31);
    font-weight: 300;
    line-height: calc(46 / 39);
    @include sm {
        @include fluid(font-size, 768, 1440, 31, 39);
    }
}

h3 {
    @include fluid(font-size, 320, 1440, 18, 29);
    font-weight: 400;
    line-height: 1.1;
}

.text-md {
    font-size: size(18);
    @include fluid(font-size, 320, 768, 18, 20);
    line-height: calc(27 / 20);
}

.text-big {
    @include fluid(font-size, 320, 768, 29, 71);
    @include sm {
        @include fluid(font-size, 768, 1440, 71, 113);
    }
    font-weight: 200;
    line-height: 1;
}

.title-caps {
    font-size: size(12);
    line-height: 1.2;
    text-transform: uppercase;
    font-weight: 400;
}

.caps {
    text-transform: uppercase;
}

.num-inline {
    color: var(--pink);
    font-size: size(10);
    font-weight: 500;
    line-height: 1;
    display: inline-block;
    transform: translate(0, -50%);
}

a {
    color: #fff;
    text-decoration: none;
    &:hover,
    &:focus {
        color: var(--pink);
    }
}
