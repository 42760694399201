@use '../helpers/mixins' as *;
@use '../helpers/vars' as *;

.what {
    * {
        transform-style: preserve-3d;
    }
}
.what .list-item {
    margin-bottom: 90px;
    perspective: 300px;
    perspective-origin: top left;
    // perspective: 300px;
    // transform-style: preserve-3d;
    position: relative;
    .num,
    .desc {
        opacity: 0;
    }
    &:last-child {
        margin-bottom: 0;
    }

    .num {
        @include fluid(font-size, 320, 768, 40, 62);
        font-weight: 300;
        line-height: 0.9;
        margin-bottom: 1rem;
        transform: rotateY(-50deg) rotateX(-30deg);

        span {
            color: var(--pink);
        }
    }
    .title-caps {
        margin-top: 1rem;
    }

    @include sm {
        display: grid;
        grid-template-columns: #{size(130)} 1fr;
        .num {
            grid-column: 1;
        }
        .desc {
            grid-column: 2;
            max-width: 36ch;
        }
        &:nth-child(2) .desc {
            max-width: 32ch;
        }
        &:nth-child(3) .desc {
            max-width: 32ch;
        }
        &:nth-child(5) .desc {
            max-width: 34ch;
        }
    }
}

.what .what-inner {
    padding-bottom: 50px;
    h2 {
        text-align: center;
        max-width: 27ch;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 50px;
        @include sm {
            margin-bottom: 90px;
        }
    }

    @include sm {
        h2 {
            margin-bottom: 90px;
        }
    }

    @include md {
        h2 {
            margin-bottom: 100px;
        }
        .steps {
            display: flex;
            flex-direction: column;
            .list-item {
                margin-bottom: 50px;
                &:nth-child(2n) {
                    align-self: end;
                }
                &:nth-child(1) {
                    margin-bottom: 0;
                }
                &:nth-child(2) {
                    margin-top: -10px;
                    margin-right: 6.5%;
                }
                &:nth-child(3) {
                    margin-top: 50px;
                    margin-bottom: 85px;
                    margin-left: 10%;
                }
                &:nth-child(4) {
                    margin-bottom: 86px;
                    margin-right: 8.5%;
                }
                &:nth-child(5) {
                    margin-bottom: 0;
                }
            }
        }
    }

    @include lg {
        h2 {
            margin-bottom: 165px;
        }
    }
}
