@use '../helpers/mixins' as *;
@use '../helpers/vars' as *;

button,
.btn,
input[type='submit'] {
    background-color: var(--pink);
    color: #fff;
    border: none;
    padding: 0 2rem;
    display: inline-block;
    border-radius: 0;
    font-size: size(22);
    text-transform: uppercase;
    letter-spacing: 0.16em;
    line-height: 2;
    white-space: nowrap;
    font-size: size(14);
    @include fluid(font-size, 320, 1200, 14, 22);
    cursor: pointer;
    transition: background-color 300ms, box-shadow 300ms;

    svg {
        width: 1.5rem;
        height: 1.5rem;
    }

    &:hover,
    &:focus {
        background-color: var(--magenta);
        //prettier-ignore
        box-shadow: 
            -3px 0 10px -5px rgba(255, 255, 255, 0.5),
            0 4px 30px hsla(300, 66%, 29%, 0.5),
            0 5px 10px -5px hsla(300, 66%, 29%, 0.1),
            0 0 40px -10px rgba(255,255,255, 0.2);
    }

    &:focus {
        outline: 2px solid white;
    }
    &:focus:not(:focus-visible) {
        outline: none;
    }
    &:focus-visible {
        outline: 2px solid white;
    }

    &.marquee {
        overflow: hidden;
        max-width: 100%;
        position: relative;

        height: size(50);
        @include fluid(height, 320, 1440, 50, 96);
        padding-left: 0;
        padding-right: 0;
        line-height: 1;
        border-radius: size(75);

        --offset: 0;
        --move-initial: calc(-25% + var(--offset));
        --move-final: calc(-50% + var(--offset));
        .marquee-inner {
            width: fit-content;
            display: flex;
            position: relative;
            transform: translate3d(-25%, 0, 0);
            animation: marquee 15s linear infinite;
            span {
                display: flex;
                align-items: center;
                padding-right: 0.5rem;
                svg {
                    margin-left: 0.5rem;
                }
            }
        }
    }

    @keyframes marquee {
        0% {
            transform: translate3d(-0%, 0, 0);
        }
        100% {
            transform: translate3d(-50%, 0, 0);
        }
    }
}
