:root {
  --pink: #ff454e;
  --blue: #1da5a5;
  --purple: #2b154c;
  --magenta: #7c197c;
  --primary: var(--pink);
  --secondary: var(--blue);
}

html {
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
}

main {
  display: block;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace;
  font-size: 1em;
}

a {
  background-color: #0000;
}

abbr[title] {
  border-bottom: none;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp {
  font-family: monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

img {
  border-style: none;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring, [type="button"]:-moz-focusring, [type="reset"]:-moz-focusring, [type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: .35em .75em .625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  max-width: 100%;
  white-space: normal;
  padding: 0;
  display: table;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template, [hidden] {
  display: none;
}

@font-face {
  font-family: galanogrotesque;
  src: url("galano-grotesque-thin.69816e14.woff2") format("woff2"), url("galano-grotesque-thin.8dfe3985.woff") format("woff");
  font-weight: 200;
}

@font-face {
  font-family: galanogrotesque;
  src: url("galano-grotesque-light.8aa69202.woff2") format("woff2"), url("galano-grotesque-light.7b4f61a7.woff") format("woff");
  font-weight: 300;
}

@font-face {
  font-family: galanogrotesque;
  src: url("galano-grotesque-reg.17d6f843.woff2") format("woff2"), url("galano-grotesque-reg.a43d2b73.woff") format("woff");
  font-weight: 400;
}

@font-face {
  font-family: galanogrotesque;
  src: url("galano-grotesque-med.5c7b175b.woff2") format("woff2"), url("galano-grotesque-med.8e78f1d4.woff") format("woff");
  font-weight: 500;
}

@font-face {
  font-family: galanogrotesque;
  src: url("galano-grotesque-semibold.29938492.woff2") format("woff2"), url("galano-grotesque-semibold.61a27e03.woff") format("woff");
  font-weight: 600;
}

@font-face {
  font-family: galanogrotesque;
  src: url("galano-grotesque-bold.b434c38f.woff2") format("woff2"), url("galano-grotesque-bold.f54080fc.woff") format("woff");
  font-weight: 700;
}

html, body {
  color: #fff;
  font-family: galanogrotesque, sans-serif;
}

body {
  font-weight: 300;
  line-height: 1.35;
}

@media (min-width: 320px) {
  body {
    font-size: clamp(.9375rem, .848214rem + .446429vw, 1.0625rem);
  }
}

@media (min-width: 768px) {
  body {
    font-size: 1.0625rem;
  }
}

:is(h1, h2, h3, h4, h5, h6) {
  margin-top: 0;
  margin-bottom: 1rem;
}

.title-bold {
  text-transform: uppercase;
  letter-spacing: -.1875rem;
  font-weight: 600;
  line-height: .8;
}

@media (min-width: 320px) {
  .b1 {
    font-size: clamp(4.1875rem, .0357143rem + 20.7589vw, 10rem);
  }
}

@media (min-width: 768px) {
  .b1 {
    font-size: 10rem;
  }
}

@media (min-width: 768px) and (min-width: 768px) {
  .b1 {
    font-size: clamp(10rem, 8.57143rem + 2.97619vw, 11.25rem);
  }
}

@media (min-width: 768px) and (min-width: 1440px) {
  .b1 {
    font-size: 11.25rem;
  }
}

@media (min-width: 320px) {
  .b2 {
    font-size: clamp(3.5625rem, .0357143rem + 17.6339vw, 8.5rem);
  }
}

@media (min-width: 768px) {
  .b2 {
    font-size: 8.5rem;
  }
}

@media (min-width: 768px) and (min-width: 768px) {
  .b2 {
    font-size: clamp(8.5rem, 5.35714rem + 6.54762vw, 11.25rem);
  }
}

@media (min-width: 768px) and (min-width: 1440px) {
  .b2 {
    font-size: 11.25rem;
  }
}

.title-1, h1 {
  font-weight: 200;
  line-height: 1.25;
}

@media (min-width: 320px) {
  .title-1, h1 {
    font-size: clamp(1.75rem, 1.03571rem + 3.57143vw, 2.75rem);
  }
}

@media (min-width: 768px) {
  .title-1, h1 {
    font-size: 2.75rem;
    line-height: 1.182;
  }
}

@media (min-width: 768px) and (min-width: 768px) {
  .title-1, h1 {
    font-size: clamp(2.75rem, 1.82143rem + 1.93452vw, 3.5625rem);
  }
}

@media (min-width: 768px) and (min-width: 1440px) {
  .title-1, h1 {
    font-size: 3.5625rem;
  }
}

@media (min-width: 1200px) {
  .title-1, h1 {
    line-height: 1.05;
  }
}

.title-2, h2 {
  font-weight: 300;
  line-height: 1.17949;
}

@media (min-width: 320px) {
  .title-2, h2 {
    font-size: clamp(1.375rem, .973214rem + 2.00893vw, 1.9375rem);
  }
}

@media (min-width: 768px) {
  .title-2, h2 {
    font-size: 1.9375rem;
  }
}

@media (min-width: 768px) and (min-width: 768px) {
  .title-2, h2 {
    font-size: clamp(1.9375rem, 1.36607rem + 1.19048vw, 2.4375rem);
  }
}

@media (min-width: 768px) and (min-width: 1440px) {
  .title-2, h2 {
    font-size: 2.4375rem;
  }
}

h3 {
  font-weight: 400;
  line-height: 1.1;
}

@media (min-width: 320px) {
  h3 {
    font-size: clamp(1.125rem, .928571rem + .982143vw, 1.8125rem);
  }
}

@media (min-width: 1440px) {
  h3 {
    font-size: 1.8125rem;
  }
}

.text-md {
  font-size: 1.125rem;
  line-height: 1.35;
}

@media (min-width: 320px) {
  .text-md {
    font-size: clamp(1.125rem, 1.03571rem + .446429vw, 1.25rem);
  }
}

@media (min-width: 768px) {
  .text-md {
    font-size: 1.25rem;
  }
}

.text-big {
  font-weight: 200;
  line-height: 1;
}

@media (min-width: 320px) {
  .text-big {
    font-size: clamp(1.8125rem, 9.375vw - .0625rem, 4.4375rem);
  }
}

@media (min-width: 768px) {
  .text-big {
    font-size: 4.4375rem;
  }
}

@media (min-width: 768px) and (min-width: 768px) {
  .text-big {
    font-size: clamp(4.4375rem, 1.4375rem + 6.25vw, 7.0625rem);
  }
}

@media (min-width: 768px) and (min-width: 1440px) {
  .text-big {
    font-size: 7.0625rem;
  }
}

.title-caps {
  text-transform: uppercase;
  font-size: .75rem;
  font-weight: 400;
  line-height: 1.2;
}

.caps {
  text-transform: uppercase;
}

.num-inline {
  color: var(--pink);
  font-size: .625rem;
  font-weight: 500;
  line-height: 1;
  display: inline-block;
  transform: translate(0, -50%);
}

a {
  color: #fff;
  text-decoration: none;
}

a:hover, a:focus {
  color: var(--pink);
}

.header-bg svg {
  height: auto;
  width: 100%;
  position: absolute;
  top: 0;
  overflow: visible;
  transform: translate3d(0, 0, -1px);
}

.header-bg {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

nav {
  width: 100%;
  justify-content: space-between;
  padding-top: 50px;
  display: flex;
  position: absolute;
}

@media (min-width: 990px) {
  nav {
    padding-top: 88px;
  }
}

nav a {
  z-index: 1;
  display: inline-block;
  position: relative;
}

nav a:after {
  content: "";
  width: calc(100% + 10px);
  height: 100%;
  background-color: var(--purple);
  z-index: -1;
  transform-origin: 0;
  transition: transform .3s ease-out;
  position: absolute;
  top: 0;
  left: -5px;
  transform: scaleX(0);
}

nav a:hover, nav a:focus {
  color: #fff;
  font-weight: 500;
}

nav a:hover:after, nav a:focus:after {
  transform: scaleX(1);
}

.computer-wrap .brought {
  letter-spacing: .47em;
  z-index: 9;
  justify-content: center;
  align-items: center;
  margin-top: 3%;
  font-size: .5625rem;
  display: flex;
  position: relative;
}

.computer-wrap .brought a {
  display: inline-block;
}

.computer-wrap .brought a:hover svg g, .computer-wrap .brought a:focus svg g {
  fill: var(--blue);
}

.computer-wrap .brought svg {
  width: 66px;
  margin-left: .5rem;
  display: inline-block;
}

.header .h1-wrap {
  padding-left: 6.5%;
  padding-right: 6.5%;
}

.header .caps-md {
  font-size: 1.25rem;
}

@media (min-width: 320px) {
  .header .caps-md {
    font-size: clamp(1.25rem, .982143rem + 1.33929vw, 1.625rem);
  }
}

@media (min-width: 768px) {
  .header .caps-md {
    font-size: 1.625rem;
  }
}

@media (min-width: 768px) and (min-width: 768px) {
  .header .caps-md {
    font-size: clamp(1.625rem, 1.41071rem + .446429vw, 1.8125rem);
  }
}

@media (min-width: 768px) and (min-width: 1440px) {
  .header .caps-md {
    font-size: 1.8125rem;
  }
}

.header .caps-lg {
  font-size: 1.875rem;
}

@media (min-width: 320px) {
  .header .caps-lg {
    font-size: clamp(1.875rem, 1.20536rem + 3.34821vw, 2.8125rem);
  }
}

@media (min-width: 768px) {
  .header .caps-lg {
    font-size: 2.8125rem;
  }
}

@media (min-width: 768px) and (min-width: 768px) {
  .header .caps-lg {
    font-size: clamp(2.8125rem, 2.3125rem + 1.04167vw, 3.25rem);
  }
}

@media (min-width: 768px) and (min-width: 1440px) {
  .header .caps-lg {
    font-size: 3.25rem;
  }
}

.header h1 {
  text-align: center;
  grid-template-columns: auto auto;
  grid-template-areas: "img img"
                       "non non"
                       "bs bs"
                       "web site"
                       "cons cons";
  display: grid;
}

.header h1 img {
  height: 4.6875rem;
  z-index: 1;
  grid-area: img;
  place-self: end center;
  margin-bottom: -.625rem;
}

@media (min-width: 320px) {
  .header h1 img {
    height: 4.6875rem;
    height: clamp(4.6875rem, 1.20536rem + 17.4107vw, 9.5625rem);
  }
}

@media (min-width: 768px) {
  .header h1 img {
    height: 9.5625rem;
  }
}

.header h1 .non {
  grid-area: non;
}

.header h1 .bs {
  grid-area: bs;
  margin-top: .4rem;
  margin-bottom: .6rem;
}

.header h1 .bs span {
  color: var(--pink);
  font-size: 60%;
  display: inline-block;
  transform: translate(-10%, -40%);
}

.header h1 .web {
  grid-area: web;
  margin-right: 15%;
}

.header h1 .site {
  grid-area: site;
}

.header h1 .cons {
  grid-area: cons;
  margin-top: .5rem;
}

@media (min-width: 768px) {
  .header h1 {
    grid-template-columns: 18% 1fr max-content;
    grid-template-areas: "img img img"
                         "non non non"
                         "bs bs bs"
                         "web site cons";
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 768px) and (min-width: 768px) {
  .header h1 {
    max-width: 39.375rem;
    max-width: clamp(39.375rem, 23.8194rem + 32.4074vw, 48.125rem);
  }
}

@media (min-width: 768px) and (min-width: 1200px) {
  .header h1 {
    max-width: 48.125rem;
  }
}

@media (min-width: 768px) {
  .header h1 img {
    margin-bottom: -6%;
  }

  .header h1 .non {
    margin-left: 10%;
  }

  .header h1 .web {
    text-align: left;
    align-self: start;
    margin: 0;
    padding: 0 15% 0 0;
  }

  .header h1 .site {
    text-align: left;
    align-self: end;
    margin: 0;
  }

  .header h1 .cons {
    margin: 0;
  }
}

@media (min-width: 1200px) {
  .header h1 {
    max-width: 56.25rem;
    grid-template-columns: 1fr 10% 57%;
    grid-template-areas: "img img non"
                         "bs bs bs"
                         "web . cons"
                         ". site cons";
  }

  .header h1 .non {
    margin: 0;
  }

  .header h1 .bs {
    text-align: left;
  }

  .header h1 img {
    margin: 0;
  }

  .header h1 .web {
    text-align: right;
    padding-right: 17%;
  }

  .header h1 .site {
    padding-left: 15%;
  }

  .header h1 .cons {
    text-align: right;
    padding-right: 10%;
  }
}

.intro {
  padding-top: 100px;
  padding-bottom: 100px;
}

@media (min-width: 768px) {
  .intro {
    padding-bottom: 140px;
  }
}

@media (min-width: 990px) {
  .intro {
    padding-bottom: 150px;
  }
}

.intro .img-bg {
  width: 100%;
  height: auto;
  opacity: .5;
  z-index: -1;
  position: absolute;
  top: 95px;
  left: 0;
}

.intro .media {
  opacity: 0;
}

.intro-inner {
  grid-template-columns: 1fr 1fr;
  grid-template-rows: var(--row-top) auto;
  --row-top: 10.625rem;
  display: grid;
}

.intro-inner h2 {
  max-width: 20ch;
  grid-area: 2 / 1 / 4 / 3;
  align-self: start;
  margin-bottom: 4.2rem;
  position: relative;
}

.intro-inner .media.guy {
  --ratio: 120%;
  grid-area: 1 / 1 / 3 / 3;
  justify-self: center;
}

@media (min-width: 320px) {
  .intro-inner .media.guy {
    max-width: 11.1875rem;
    max-width: clamp(11.1875rem, 7.75rem + 17.1875vw, 16rem);
  }
}

@media (min-width: 768px) {
  .intro-inner .media.guy {
    max-width: 16rem;
  }
}

.intro-inner .media.guy video {
  width: 150%;
  height: 150%;
  top: -15%;
  left: -18%;
}

.intro-inner .media.dog {
  grid-area: 4 / 1;
}

@media (min-width: 320px) {
  .intro-inner .media.dog {
    max-width: 5.5rem;
    max-width: clamp(5.5rem, 1.61607rem + 19.4196vw, 10.9375rem);
  }
}

@media (min-width: 768px) {
  .intro-inner .media.dog {
    max-width: 10.9375rem;
  }
}

.intro-inner .media.thumb {
  grid-area: 4 / 2;
  place-self: start end;
}

@media (min-width: 320px) {
  .intro-inner .media.thumb {
    max-width: 3.625rem;
    max-width: clamp(3.625rem, 1.03571rem + 12.9464vw, 7.25rem);
  }
}

@media (min-width: 768px) {
  .intro-inner .media.thumb {
    max-width: 7.25rem;
  }
}

.intro-inner .media.frown {
  --ratio: 33%;
  grid-area: 7 / 1 / auto / 3;
  justify-self: center;
}

@media (min-width: 320px) {
  .intro-inner .media.frown {
    max-width: 16.0625rem;
    max-width: clamp(16.0625rem, 6.375rem + 48.4375vw, 29.625rem);
  }
}

@media (min-width: 768px) {
  .intro-inner .media.frown {
    max-width: 29.625rem;
  }
}

.intro-inner .media.frown video {
  object-position: 50% top;
}

.intro-inner .text-md {
  text-align: center;
  grid-column: 1 / 3;
  margin-top: 3.6rem;
  margin-bottom: 2rem;
}

.intro-inner .text-md p {
  margin-bottom: 1.4rem;
}

@media (min-width: 768px) {
  .intro-inner {
    --row-top: 10.3125rem;
  }

  .intro-inner h2 {
    max-width: none;
    margin-bottom: 2.6rem;
    padding-left: 3.9%;
  }

  .intro-inner .media.dog {
    align-self: end;
    margin-left: 6%;
    padding-top: 2.3rem;
  }

  .intro-inner .media.thumb {
    margin-right: 20%;
  }

  .intro-inner .text-md {
    text-align: left;
    margin-top: 4.7rem;
    margin-bottom: 6rem;
    padding-left: 3.5%;
  }
}

@media (min-width: 990px) {
  .intro-inner {
    grid-template-rows: 150px 6.25rem auto 9.375rem auto;
    grid-template-columns: 20% 25% 25% 30%;
  }

  .intro-inner h2 {
    max-width: 20ch;
    grid-area: 2 / 1 / 4 / 4;
    margin: 0;
    padding-left: 0;
  }

  .intro-inner .text-md {
    max-width: 28ch;
    flex-direction: column;
    grid-area: 2 / 4 / 4;
    justify-content: space-between;
    margin: 0;
    padding: 0;
    display: flex;
  }

  .intro-inner .text-md p {
    margin: 0;
  }

  .intro-inner .media.dog {
    width: 76%;
    grid-area: 5 / 1;
    align-self: end;
    margin: 0;
    padding: 0;
  }

  .intro-inner .media.guy {
    grid-area: 1 / 2 / 3;
  }

  .intro-inner .media.thumb {
    width: 34%;
    grid-area: 5 / 3;
    justify-self: start;
    margin-top: -50px;
  }

  .intro-inner .media.frown {
    grid-area: 5 / 4;
    align-self: center;
    margin: 0;
  }
}

@media (min-width: 1200px) {
  .intro-inner {
    grid-template-columns: 20% 20% 30% 30%;
  }
}

footer.site-footer {
  background-image: linear-gradient(#000, #0000 50%), url("stars.2ee33cdb.jpg");
  background-position: bottom;
  background-size: cover;
  padding-top: 190px;
  padding-bottom: 110px;
}

@media (min-width: 768px) {
  footer.site-footer {
    padding-bottom: 160px;
  }
}

@media (min-width: 1200px) {
  footer.site-footer {
    padding-top: 235px;
    padding-bottom: 200px;
  }
}

footer.site-footer .footer-inner {
  grid-template-columns: 1fr 17%;
  grid-template-areas: "caps img"
                       "logo img"
                       "tag tag"
                       "para para"
                       "link link";
  display: grid;
}

footer.site-footer .footer-inner .caps {
  grid-area: caps;
  align-self: start;
  margin-bottom: 10px;
  font-size: .5625rem;
  line-height: 1;
}

@media (min-width: 768px) {
  footer.site-footer .footer-inner .caps {
    align-self: center;
    margin-bottom: 0;
    font-size: .75rem;
  }
}

@media (min-width: 1200px) {
  footer.site-footer .footer-inner .caps {
    font-size: .875rem;
  }
}

footer.site-footer .footer-inner a.logo-link {
  width: 160px;
  height: auto;
  grid-area: logo;
}

footer.site-footer .footer-inner a.logo-link:hover svg g, footer.site-footer .footer-inner a.logo-link:focus svg g {
  fill: var(--pink);
}

footer.site-footer .footer-inner .tagline {
  grid-area: tag;
  margin-top: 100px;
  margin-bottom: 2rem;
}

footer.site-footer .footer-inner .footer-links {
  grid-area: link;
  justify-content: space-between;
  font-size: 1.0625rem;
  display: flex;
}

footer.site-footer .footer-inner .footer-links a {
  white-space: nowrap;
}

@media (min-width: 768px) {
  footer.site-footer .footer-inner .footer-links {
    font-size: 1.875rem;
  }

  footer.site-footer .footer-inner .footer-links .num-inline {
    font-size: .75rem;
    transform: translate(-30%, -110%);
  }
}

footer.site-footer .footer-inner .footer-para {
  grid-area: para;
  margin-bottom: 90px;
}

footer.site-footer .interface {
  height: auto;
  opacity: .25;
  flex-direction: column-reverse;
  grid-area: img;
  justify-content: space-between;
  align-items: flex-end;
  display: flex;
}

footer.site-footer .interface img {
  width: 100%;
  max-width: 65px;
}

footer.site-footer .interface img:nth-child(2) {
  width: 80%;
  max-width: 45px;
}

@media (min-width: 768px) {
  footer.site-footer .interface {
    flex-direction: row;
    place-self: center end;
    align-items: center;
  }

  footer.site-footer .interface img:first-child {
    height: 12px;
    margin-right: 1rem;
  }

  footer.site-footer .interface img:last-child {
    height: 10px;
  }

  footer.site-footer .footer-inner {
    grid-template-columns: 1fr 25% 1fr;
    grid-template-areas: "caps logo img"
                         "tag tag tag"
                         "para para para"
                         "link link link";
  }

  footer.site-footer .footer-inner .footer-para {
    margin-bottom: 100px;
  }

  footer.site-footer .footer-inner .footer-links {
    align-self: end;
    margin: 0;
  }

  footer.site-footer .footer-inner .tagline {
    margin-top: 120px;
    margin-bottom: 104px;
  }
}

@media (min-width: 1200px) {
  footer.site-footer .footer-inner {
    grid-template-columns: 43% 25% 1fr;
    grid-template-areas: "caps logo img"
                         "tag tag tag"
                         "link para para";
    padding-left: 12%;
    padding-right: 13%;
  }

  footer.site-footer .footer-inner .tagline {
    margin-top: 110px;
    margin-bottom: 10%;
  }

  footer.site-footer .footer-inner .footer-para {
    margin-bottom: 0;
    margin-left: 23%;
  }

  footer.site-footer .footer-inner .footer-links {
    justify-content: start;
  }

  footer.site-footer .footer-inner .footer-links a {
    margin-right: 4rem;
  }
}

.cta .cta-inner {
  display: grid;
}

.cta .cta-inner .media.lady {
  max-width: 125px;
  max-width: 63px;
  grid-area: 1 / 1;
  margin-bottom: 90px;
  margin-left: 25%;
}

.cta .cta-inner .media.exercise {
  max-width: 375px;
  --ratio: 62%;
  width: 90%;
  grid-row: 2;
  justify-self: end;
  margin-bottom: -25px;
}

.cta .cta-inner h2 {
  max-width: 13ch;
  z-index: 2;
  grid-row: 3;
  margin-bottom: 2rem;
  position: relative;
}

.cta .cta-inner .text-md {
  margin-bottom: 4rem;
}

.cta .cta-inner button {
  grid-row: 5;
}

@media (min-width: 768px) {
  .cta .cta-inner .media.lady {
    justify-self: end;
    margin-left: 0;
  }

  .cta .cta-inner .media.exercise {
    justify-self: center;
  }

  .cta .cta-inner h2 {
    width: 80%;
    max-width: none;
    margin-inline: auto;
  }

  .cta .cta-inner .text-md {
    width: 80%;
    margin-inline: auto;
    margin-bottom: 5rem;
  }
}

@media (min-width: 990px) {
  .cta .cta-inner {
    grid-template-columns: 20% 1fr 40%;
  }

  .cta .cta-inner h2 {
    width: 100%;
    max-width: 15ch;
    grid-area: 3 / 1 / auto / 3;
    margin-left: 1.5%;
  }

  .cta .cta-inner .media.exercise {
    width: 100%;
    grid-area: 2 / 2;
    justify-self: start;
    margin-top: 2%;
    margin-bottom: -7%;
  }

  .cta .cta-inner .media.lady {
    width: 100%;
    max-width: 125px;
    grid-column: 3;
    justify-self: end;
    margin-bottom: 0;
  }

  .cta .cta-inner .text-md {
    width: 100%;
    grid-area: 4 / 3;
    margin-top: max(-11%, -55px);
    margin-bottom: min(26%, 130px);
  }

  .cta .cta-inner button {
    grid-column: 1 / 4;
  }
}

@media (min-width: 1200px) {
  .cta .cta-inner {
    grid-template-columns: 30% 1fr 40%;
  }
}

.how {
  padding-top: 100px;
  position: relative;
}

.how .how-inner {
  z-index: 2;
  display: grid;
  position: relative;
}

.how .how-inner h2 {
  grid-row: 2;
  margin-bottom: 60px;
}

.how .how-inner .media.eyes {
  max-width: 253px;
  width: 100%;
  --ratio: 37%;
  grid-row: 3;
  justify-self: center;
}

.how .how-inner .media.eyes video {
  width: 110%;
  height: 140%;
  top: 0;
  left: -5%;
}

.how .how-inner .media.fingers {
  max-width: 63px;
  width: 30%;
  grid-row: 1;
  margin-bottom: 50px;
  margin-left: 12%;
}

@media (min-width: 768px) {
  .how .how-inner h2 {
    margin-bottom: 88px;
  }

  .how .how-inner .media.fingers {
    max-width: 87px;
    grid-row: 1;
    margin-bottom: 70px;
    margin-left: 27%;
    display: block;
  }

  .how .how-inner .media.eyes {
    max-width: 364px;
  }
}

@media (min-width: 1200px) {
  .how {
    padding-top: 150px;
  }

  .how .how-inner h2 {
    margin-bottom: 1rem;
  }

  .how .how-inner .media.eyes {
    max-width: 252px;
    width: 50%;
    --ratio: 46%;
    grid-row: 3;
    justify-self: end;
    margin-right: -1%;
  }

  .how .how-inner .media.fingers {
    max-width: 125px;
    width: 20%;
    grid-row: 1;
    margin: 0 0 1rem;
  }
}

@media (min-width: 1440px) {
  .how .how-inner .media.fingers {
    margin-bottom: -2%;
  }
}

.how h2 {
  text-align: center;
  justify-content: center;
  display: grid;
}

.how h2 img {
  margin-inline: auto;
  margin-bottom: calc(var(--bottom) * -1);
  z-index: 1;
  display: block;
}

@media (min-width: 320px) {
  .how h2 img {
    height: 3.125rem;
    height: clamp(3.125rem, 2.23214rem + 4.46429vw, 4.375rem);
  }
}

@media (min-width: 768px) {
  .how h2 img {
    height: 4.375rem;
  }
}

@media (min-width: 320px) {
  .how h2 img {
    --bottom: 1rem;
    --bottom: clamp(1rem, .375rem + (.03125 * 100vw), 1.875rem);
  }
}

@media (min-width: 768px) {
  .how h2 img {
    --bottom: 1.875rem;
  }
}

.how h2 .bs span, .how h2 .nonbs span {
  color: var(--pink);
  font-size: 60%;
  display: inline-block;
  transform: translate(-10%, -40%);
}

.how h2 span {
  white-space: nowrap;
  line-height: .9;
}

@media (min-width: 320px) {
  .how h2 .non {
    font-size: clamp(4.1875rem, .0357143rem + 20.7589vw, 10rem);
  }
}

@media (min-width: 768px) {
  .how h2 .non {
    font-size: 10rem;
  }
}

.how h2 .bs {
  letter-spacing: -.03125rem;
  margin-bottom: .5rem;
  font-weight: 500;
}

@media (min-width: 320px) {
  .how h2 .bs {
    font-size: clamp(3.5625rem, .0357143rem + 17.6339vw, 8.5rem);
  }
}

@media (min-width: 768px) {
  .how h2 .bs {
    font-size: 8.5rem;
  }
}

.how h2 .desk {
  display: none;
}

.how h2 .cons, .how h2 .consist {
  letter-spacing: 0;
  font-weight: 500;
  line-height: 1.1;
}

@media (min-width: 320px) {
  .how h2 .cons, .how h2 .consist {
    font-size: clamp(2.125rem, .383929rem + 8.70536vw, 4.5625rem);
  }
}

@media (min-width: 768px) {
  .how h2 .cons, .how h2 .consist {
    font-size: 4.5625rem;
  }
}

@media (min-width: 768px) and (min-width: 768px) {
  .how h2 .cons, .how h2 .consist {
    font-size: clamp(4.5625rem, 1.34028rem + 6.71296vw, 6.375rem);
  }
}

@media (min-width: 768px) and (min-width: 1200px) {
  .how h2 .cons, .how h2 .consist {
    font-size: 6.375rem;
  }
}

@media (min-width: 768px) {
  .how h2 {
    grid-template-columns: calc(var(--img-width)  - .7rem) 1fr;
    width: 100%;
    display: grid;
  }
}

@media (min-width: 768px) and (min-width: 768px) {
  .how h2 {
    --img-width: 6.4375rem;
    --img-width: clamp(6.4375rem, 3.4375rem + (.0625 * 100vw), 8.125rem);
  }
}

@media (min-width: 768px) and (min-width: 1200px) {
  .how h2 {
    --img-width: 8.125rem;
  }
}

@media (min-width: 768px) {
  .how h2 img {
    width: var(--img-width);
    max-width: none;
    height: auto;
    grid-area: 1 / 1;
    align-self: center;
    margin-bottom: 0;
    margin-right: -.75rem;
  }

  .how h2 .mob {
    display: none;
  }

  .how h2 .cons {
    text-align: left;
    grid-area: 2 / 1 / auto / 3;
    justify-self: start;
    padding-left: 1.5%;
    line-height: 1;
  }

  .how h2 .consist {
    text-align: right;
    grid-area: 3 / 1 / auto / 3;
    justify-self: end;
    padding-right: 2.5%;
    line-height: .9;
  }

  .how h2 .nonbs.desk {
    letter-spacing: -.03125rem;
    grid-area: 1 / 2;
    display: block;
  }
}

@media (min-width: 768px) and (min-width: 768px) {
  .how h2 .nonbs.desk {
    font-size: clamp(4.5625rem, 1.34028rem + 6.71296vw, 6.375rem);
  }
}

@media (min-width: 768px) and (min-width: 1200px) {
  .how h2 .nonbs.desk {
    font-size: 6.375rem;
  }
}

@media (min-width: 768px) {
  .how h2 .nonbs.desk span {
    letter-spacing: .3125rem;
    font-size: 70%;
    transform: translate(10%, -30%);
  }
}

@media (min-width: 1200px) {
  .how h2 {
    width: 90%;
    grid-template-columns: 10% 8.125rem 1fr;
    margin-left: auto;
    margin-right: auto;
  }

  .how h2 img {
    width: 8.125rem;
    grid-column: 2;
    justify-self: end;
  }

  .how h2 .nonbs.desk {
    text-align: left;
    grid-area: 1 / 3;
    justify-self: start;
    font-size: 6.375rem;
  }

  .how h2 .cons {
    grid-column: 1 / 4;
    justify-self: start;
    padding-left: 0;
    font-size: 6.1875rem;
    line-height: .9;
  }

  .how h2 .consist {
    grid-area: 3 / 3;
    justify-self: center;
    margin: 0;
    font-size: 5.6875rem;
    line-height: .8;
  }
}

@media (min-width: 1440px) {
  .how h2 {
    width: 75%;
    grid-template-columns: 2.5% 8.125rem 1fr;
  }
}

.how-steps {
  margin-top: 105px;
  margin-bottom: 10px;
  display: grid;
  position: relative;
}

.how-steps .num {
  letter-spacing: -.02em;
  transform-style: preserve-3d;
  perspective: 300px;
  grid-template-columns: min-content 1fr;
  align-items: center;
  font-size: 10.9375rem;
  font-weight: 200;
  line-height: .7;
  display: grid;
}

.how-steps .num .digit {
  color: var(--pink);
  grid-area: 1 / 2;
  justify-self: start;
  display: block;
}

.how-steps .num.desk {
  display: none;
}

.how-steps .num.mobile {
  margin-bottom: 2rem;
  margin-left: -.8rem;
}

@media (min-width: 768px) {
  .how-steps .num {
    margin-bottom: 0;
  }
}

@media (min-width: 768px) and (min-width: 768px) {
  .how-steps .num {
    font-size: clamp(10.9375rem, 22.9167vw - .0625rem, 20.5625rem);
  }
}

@media (min-width: 768px) and (min-width: 1440px) {
  .how-steps .num {
    font-size: 20.5625rem;
  }
}

@media (min-width: 768px) {
  .how-steps .num.mobile {
    display: none;
  }

  .how-steps .num.desk {
    display: grid;
  }

  .how-steps .num .digit {
    opacity: 0;
    display: block;
    transform: translateY(-100%);
  }
}

.how-steps .title-caps {
  letter-spacing: .47em;
  margin-bottom: 2rem;
}

@media (min-width: 1200px) {
  .how-steps .title-caps {
    margin-bottom: 4rem;
  }
}

.how-steps .step {
  margin-bottom: 95px;
  line-height: 1.5;
}

@media (min-width: 320px) {
  .how-steps .step {
    font-size: clamp(1.1875rem, .964286rem + 1.11607vw, 1.5rem);
  }
}

@media (min-width: 768px) {
  .how-steps .step {
    font-size: 1.5rem;
  }
}

@media (min-width: 768px) and (min-width: 768px) {
  .how-steps .step {
    font-size: clamp(1.5rem, 1.07143rem + .892857vw, 1.875rem);
  }
}

@media (min-width: 768px) and (min-width: 1440px) {
  .how-steps .step {
    font-size: 1.875rem;
  }
}

@media (min-width: 768px) {
  .how-steps {
    grid-template-rows: auto 1fr;
    grid-template-columns: 1fr auto;
    margin-bottom: 80px;
    padding-left: 5%;
    padding-right: 5%;
    display: grid;
  }

  .how-steps .step {
    max-width: 18ch;
    min-height: 100vh;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 0;
    display: flex;
  }

  .how-steps .num {
    grid-area: 1 / 1;
    position: sticky;
    top: 0%;
  }

  .how-steps .step {
    min-height: 100vh;
    flex-direction: column;
    grid-column: 2;
    justify-content: center;
    display: flex;
  }
}

@media (min-width: 990px) {
  .how-steps {
    padding-left: 8%;
    padding-right: 8%;
  }
}

@media (min-width: 1200px) {
  .how-steps {
    margin-top: -100px;
    padding-left: 10%;
    padding-right: 12%;
  }
}

.what * {
  transform-style: preserve-3d;
}

.what .list-item {
  perspective: 300px;
  perspective-origin: 0 0;
  margin-bottom: 90px;
  position: relative;
}

.what .list-item .num, .what .list-item .desc {
  opacity: 0;
}

.what .list-item:last-child {
  margin-bottom: 0;
}

.what .list-item .num {
  margin-bottom: 1rem;
  font-weight: 300;
  line-height: .9;
  transform: rotateY(-50deg)rotateX(-30deg);
}

@media (min-width: 320px) {
  .what .list-item .num {
    font-size: clamp(2.5rem, 1.51786rem + 4.91071vw, 3.875rem);
  }
}

@media (min-width: 768px) {
  .what .list-item .num {
    font-size: 3.875rem;
  }
}

.what .list-item .num span {
  color: var(--pink);
}

.what .list-item .title-caps {
  margin-top: 1rem;
}

@media (min-width: 768px) {
  .what .list-item {
    grid-template-columns: 8.125rem 1fr;
    display: grid;
  }

  .what .list-item .num {
    grid-column: 1;
  }

  .what .list-item .desc {
    max-width: 36ch;
    grid-column: 2;
  }

  .what .list-item:nth-child(2) .desc, .what .list-item:nth-child(3) .desc {
    max-width: 32ch;
  }

  .what .list-item:nth-child(5) .desc {
    max-width: 34ch;
  }
}

.what .what-inner {
  padding-bottom: 50px;
}

.what .what-inner h2 {
  text-align: center;
  max-width: 27ch;
  margin-bottom: 50px;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 768px) {
  .what .what-inner h2 {
    margin-bottom: 90px;
  }
}

@media (min-width: 990px) {
  .what .what-inner h2 {
    margin-bottom: 100px;
  }

  .what .what-inner .steps {
    flex-direction: column;
    display: flex;
  }

  .what .what-inner .steps .list-item {
    margin-bottom: 50px;
  }

  .what .what-inner .steps .list-item:nth-child(2n) {
    align-self: end;
  }

  .what .what-inner .steps .list-item:nth-child(1) {
    margin-bottom: 0;
  }

  .what .what-inner .steps .list-item:nth-child(2) {
    margin-top: -10px;
    margin-right: 6.5%;
  }

  .what .what-inner .steps .list-item:nth-child(3) {
    margin-top: 50px;
    margin-bottom: 85px;
    margin-left: 10%;
  }

  .what .what-inner .steps .list-item:nth-child(4) {
    margin-bottom: 86px;
    margin-right: 8.5%;
  }

  .what .what-inner .steps .list-item:nth-child(5) {
    margin-bottom: 0;
  }
}

@media (min-width: 1200px) {
  .what .what-inner h2 {
    margin-bottom: 165px;
  }
}

.header {
  --pt: 15vh;
  --ht: 20vh;
  padding-top: var(--pt);
  --size: Min(47vw, 90vh, 680px);
  --scene-height: calc(var(--size) * 1.3);
  --top-height: var(--size);
  --perspective: 3000;
  --plane-height: var(--size);
  --plane-width: var(--top-width);
  --top-x: 0;
  --top-y: calc(var(--top-height) * -1);
  --top-z: 0;
  --top-width: calc(var(--top-height) * 1.48);
  --top-depth: calc(var(--top-height) * .021);
  --top-origin-x: 0%;
  --top-origin-y: 100%;
  --top-origin-z: calc(var(--top-depth) / 2);
  --bot-x: 0;
  --bot-y: 0;
  --bot-z: calc(var(--bot-depth) / 2);
  --bot-depth: calc(var(--bot-width) * .675);
  --bot-height: calc(var(--bot-width) * .032);
  --bot-width: var(--top-width);
  display: grid;
  position: relative;
}

.header .h1-wrap {
  top: var(--ht);
  grid-area: 1 / 1;
  align-self: start;
  padding-bottom: 50px;
  position: sticky;
}

.header .computer-wrap {
  top: max(var(--ht)  + 17.5rem, var(--top-height) * 1.1);
  grid-area: 2 / 1;
  align-self: start;
  position: sticky;
}

@media (min-width: 400px) {
  .header .computer-wrap {
    top: max(var(--ht)  + 21.25rem, var(--top-height) * 1.1);
  }
}

@media (min-width: 480px) {
  .header .computer-wrap {
    top: max(var(--ht)  + 26.25rem, var(--top-height) * 1.1);
  }
}

.header .spacer {
  height: 100vh;
  grid-area: 2 / 1;
}

@media (min-width: 990px) {
  .header .h1-wrap {
    padding-bottom: 80px;
  }
}

@media (min-width: 1200px) {
  .header {
    --pt: 200px;
    --ht: 150px;
  }

  .header .computer-wrap {
    top: max(50%, var(--top-height) * 1.1);
  }
}

.computer {
  height: 30px;
  width: 100%;
  z-index: 99;
  justify-content: center;
  align-items: flex-start;
  display: flex;
}

.computer .scene {
  perspective-origin: 50% 0;
  transform-style: preserve-3d;
  perspective: calc(var(--perspective, 800) * 1px);
  position: relative;
}

.computer .scene * {
  transform-style: preserve-3d;
}

.computer .plane {
  height: var(--plane-height);
  width: var(--plane-width);
}

.cuboid {
  height: var(--h);
  width: var(--w);
  position: absolute;
}

.cuboid img {
  display: block;
}

.cuboid .cuboid__side {
  position: absolute;
}

.cuboid.bottom {
  --w: var(--bot-width);
  --h: var(--bot-height);
  --d: var(--bot-depth);
  transform: translate3d(var(--bot-x), var(--bot-y), var(--bot-z));
}

.cuboid.bottom #laptop-bottom {
  height: var(--h);
  width: var(--w);
  transform: translate3d(0, 0, calc(var(--d) * .5));
}

.cuboid.top {
  --w: var(--top-width);
  --h: var(--top-height);
  --d: var(--top-depth);
  transform-origin: var(--top-origin-x) var(--top-origin-y) var(--top-origin-z);
  transform: translate3d(var(--top-x), var(--top-y), var(--top-z)) rotateX(0deg);
}

.cuboid.top #laptop-screen {
  height: var(--h);
  width: var(--w);
  transform: translate3d(0, 0, calc(var(--d) * .5));
}

.cuboid.top #laptop-screen .img-container {
  width: 90%;
  height: 86%;
  border-radius: 3px;
  position: absolute;
  top: 7%;
  left: 5%;
  overflow: hidden;
  transform: translate3d(0, 0, 2px);
}

.cuboid.top #laptop-screen img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: relative;
  transform: translate3d(0, 0, 1px);
}

.cuboid.top #laptop-top {
  height: var(--d);
  width: var(--w);
  transform-origin: 0 100%;
  transform: translate(-50%, -50%) translate3d(0, calc(var(--h) * -.5 - var(--d) / 2), calc(var(--d) / 2)) rotateX(90deg);
  top: 50%;
  left: 50%;
}

.cuboid.top #laptop-top img {
  width: 100%;
  height: 100%;
  transform-origin: 50% 100%;
  width: 99.5%;
  height: 100%;
  position: absolute;
  left: .25%;
  transform: rotateX(40deg);
}

.modal {
  --tDuration: .5s;
  opacity: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  transition: all var(--tDuration);
  justify-content: center;
  align-items: center;
  position: fixed;
  inset: 0;
}

.modal .modal-inner {
  width: 100%;
  opacity: 0;
  transition: all var(--tDuration) ease-out;
  position: relative;
  transform: translateY(200px);
}

.modal.is-open {
  z-index: 999;
  pointer-events: all;
  opacity: 1;
}

.modal.is-open .modal-inner {
  opacity: 1;
  transform: none;
}

@media (prefers-reduced-motion) {
  .modal {
    transition: opacity .2s linear;
  }

  .modal .modal-inner {
    transition: none;
  }
}

.modal-inner {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background-color: #000;
  position: relative;
}

button.modal-close {
  letter-spacing: 0;
  width: 2.5rem;
  height: 2.5rem;
  cursor: pointer;
  background: none;
  border: 0;
  border-radius: 0;
  padding: 0;
  line-height: 1;
}

button.modal-close:before {
  content: "✕";
  font-size: 1.25rem;
}

button.modal-close:hover, button.modal-close:focus {
  color: var(--blue);
  box-shadow: none;
  background: none;
}

button.modal-close:focus {
  outline: 2px solid var(--secondary);
}

button.modal-close:focus:not(:focus-visible) {
  outline: none;
}

button.modal-close:focus-visible {
  outline: 2px solid var(--secondary);
}

.modal .modal-content {
  scrollbar-width: thin;
  scrollbar-color: #7c197c80 #1b1b1b;
  height: 100%;
  grid-template-columns: 1fr min-content;
  padding-top: 2.7rem;
  padding-bottom: 3rem;
  display: grid;
  overflow: auto;
}

.modal .modal-content::-webkit-scrollbar-track {
  background-color: #1b1b1b;
}

.modal .modal-content::-webkit-scrollbar-thumb {
  opacity: .6;
  background-color: #7c197c80;
  border-radius: 10px;
}

.modal .modal-content::-webkit-scrollbar-thumb:hover {
  background-color: #7c197ce6;
}

.modal .modal-content::-webkit-scrollbar {
  width: 10px;
  display: block;
}

.modal .modal-content .topline {
  display: none;
}

.modal .modal-content .logo-wrap {
  width: 6.375rem;
  grid-area: 1 / 1;
}

.modal .modal-content .logo-wrap svg {
  width: 100%;
  height: auto;
}

.modal .modal-content .logo-wrap .title-caps {
  display: none;
}

.modal .modal-content button.modal-close {
  grid-area: 1 / 2;
  margin-top: -.3125rem;
}

.modal .modal-content h1, .modal .modal-content .contact-form, .modal .modal-content .contact-form-desc {
  grid-column: 1 / 3;
}

.modal .modal-content .contact-form {
  width: 100%;
}

.modal .modal-content .contact-form form {
  flex-wrap: wrap;
  margin: 0;
  display: flex;
}

.modal .modal-content .contact-form form .input-wrap {
  flex: 0 0 100%;
  margin-bottom: .5rem;
}

.modal .modal-content h1 {
  margin-top: 2rem;
}

@media (min-width: 768px) {
  .modal .modal-content {
    grid-template-columns: 1fr 40% auto;
    padding: 80px 7.6% 100px;
  }

  .modal .modal-content .topline {
    grid-area: 1 / 2;
    align-self: center;
    display: block;
  }

  .modal .modal-content button.modal-close {
    grid-area: 1 / 3;
    align-self: center;
    margin: 0;
  }

  .modal .modal-content h1, .modal .modal-content .form, .modal .modal-content .form-info {
    grid-column: 1 / 4;
  }

  .modal .modal-content .title-caps {
    color: #676767;
    font-size: .5rem;
  }

  .modal .modal-content .logo-wrap {
    width: 7.625rem;
  }

  .modal .modal-content .logo-wrap .title-caps {
    margin-bottom: .5rem;
    display: block;
  }

  .modal .modal-content h1 {
    margin-top: 100px;
  }

  .modal .modal-content .form-desc {
    margin-bottom: 40px;
  }

  .modal .modal-content .contact-form form {
    justify-content: space-between;
  }

  .modal .modal-content .contact-form form .input-wrap {
    flex: 0 0 47.5%;
    margin-bottom: 1rem;
  }

  .modal .modal-content .contact-form form button[type="submit"] {
    margin-top: 70px;
  }
}

@media (min-width: 1200px) {
  .modal .modal-content {
    grid-template-rows: min-content min(7.5vh, 67px) min-content min-content;
    grid-template-columns: 1fr 7.625rem;
    padding-left: 6.4%;
    padding-right: 6.4%;
  }

  .modal .modal-content .topline {
    grid-area: 1 / 1;
  }

  .modal .modal-content button.modal-close {
    grid-area: 1 / 2;
    justify-self: end;
  }

  .modal .modal-content .logo-wrap {
    grid-area: 3 / 2;
    margin-top: 1.875rem;
  }

  .modal .modal-content .logo-wrap .title-caps {
    text-align: right;
  }

  .modal .modal-content .form-info {
    grid-area: 3 / 1 / auto / 3;
    margin-bottom: min(5vh, 45px);
  }

  .modal .modal-content .form-info h1 {
    max-width: 13ch;
    margin: 0;
    display: inline;
  }

  .modal .modal-content .form-info .form-desc {
    float: right;
    max-width: 46ch;
    margin: 1.5rem 0 0;
    font-size: 1rem;
  }

  .modal .modal-content .contact-form {
    grid-row: 4;
  }

  .modal .modal-content .contact-form form .input-wrap {
    flex: 0 0 24%;
  }

  .modal .modal-content .contact-form form button[type="submit"] {
    margin-top: min(8vh, 80px);
  }
}

@media (min-width: 1440px) {
  .modal .modal-content .form-info .form-desc {
    max-width: 52ch;
    margin-top: 3rem;
    font-size: 1.0625rem;
  }
}

.media {
  width: 100%;
  --ratio: 100%;
  position: relative;
}

.media .media-inner {
  width: 100%;
  padding-bottom: var(--ratio);
  height: 0;
  position: relative;
  overflow: hidden;
}

.media .media-inner img, .media .media-inner video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}

button, .btn, input[type="submit"] {
  background-color: var(--pink);
  color: #fff;
  text-transform: uppercase;
  letter-spacing: .16em;
  white-space: nowrap;
  cursor: pointer;
  border: none;
  border-radius: 0;
  padding: 0 2rem;
  font-size: .875rem;
  line-height: 2;
  transition: background-color .3s, box-shadow .3s;
  display: inline-block;
}

@media (min-width: 320px) {
  button, .btn, input[type="submit"] {
    font-size: clamp(.875rem, .693182rem + .909091vw, 1.375rem);
  }
}

@media (min-width: 1200px) {
  button, .btn, input[type="submit"] {
    font-size: 1.375rem;
  }
}

button svg, .btn svg, input[type="submit"] svg {
  width: 1.5rem;
  height: 1.5rem;
}

button:hover, button:focus, .btn:hover, .btn:focus, input[type="submit"]:hover, input[type="submit"]:focus {
  background-color: var(--magenta);
  box-shadow: -3px 0 10px -5px #ffffff80, 0 4px 30px #7b197b80, 0 5px 10px -5px #7b197b1a, 0 0 40px -10px #fff3;
}

button:focus, .btn:focus, input[type="submit"]:focus {
  outline: 2px solid #fff;
}

button:focus:not(:focus-visible), .btn:focus:not(:focus-visible), input[type="submit"]:focus:not(:focus-visible) {
  outline: none;
}

button:focus-visible, .btn:focus-visible, input[type="submit"]:focus-visible {
  outline: 2px solid #fff;
}

button.marquee, .btn.marquee, input[type="submit"].marquee {
  max-width: 100%;
  height: 3.125rem;
  --offset: 0;
  --move-initial: calc(-25% + var(--offset));
  --move-final: calc(-50% + var(--offset));
  border-radius: 4.6875rem;
  padding-left: 0;
  padding-right: 0;
  line-height: 1;
  position: relative;
  overflow: hidden;
}

@media (min-width: 320px) {
  button.marquee, .btn.marquee, input[type="submit"].marquee {
    height: 3.125rem;
    height: clamp(3.125rem, 2.30357rem + 4.10714vw, 6rem);
  }
}

@media (min-width: 1440px) {
  button.marquee, .btn.marquee, input[type="submit"].marquee {
    height: 6rem;
  }
}

button.marquee .marquee-inner, .btn.marquee .marquee-inner, input[type="submit"].marquee .marquee-inner {
  width: -moz-fit-content;
  width: fit-content;
  animation: 15s linear infinite marquee;
  display: flex;
  position: relative;
  transform: translate3d(-25%, 0, 0);
}

button.marquee .marquee-inner span, .btn.marquee .marquee-inner span, input[type="submit"].marquee .marquee-inner span {
  align-items: center;
  padding-right: .5rem;
  display: flex;
}

button.marquee .marquee-inner span svg, .btn.marquee .marquee-inner span svg, input[type="submit"].marquee .marquee-inner span svg {
  margin-left: .5rem;
}

@keyframes marquee {
  0% {
    transform: translate3d(0%, 0, 0);
  }

  100% {
    transform: translate3d(-50%, 0, 0);
  }
}

form button[type="submit"] {
  margin-top: 1rem;
}

form label span.req {
  color: var(--pink);
}

.form-message {
  margin-top: 2rem;
}

.form-response {
  text-align: center;
  color: var(--pink);
  margin-top: 1rem;
}

.form-response:focus {
  outline: 2px solid var(--blue);
}

.form-response:focus:not(:focus-visible) {
  outline: none;
}

.form-response:focus-visible {
  outline: 2px solid var(--blue);
}

[type="color"], [type="date"], [type="datetime"], [type="datetime-local"], [type="email"], [type="month"], [type="number"], [type="password"], [type="search"], [type="tel"], [type="text"], [type="time"], [type="url"], [type="week"], input:not([type]), textarea {
  width: 100%;
  color: #ebebeb;
  color-scheme: dark;
  background-color: #1b1a1a;
  border: 1px solid #0000;
  outline: none;
  margin: 0;
  padding: 1rem .875rem;
  font-size: .9375rem;
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 768px) {
  [type="color"], [type="date"], [type="datetime"], [type="datetime-local"], [type="email"], [type="month"], [type="number"], [type="password"], [type="search"], [type="tel"], [type="text"], [type="time"], [type="url"], [type="week"], input:not([type]), textarea {
    padding: 1.25rem;
    font-size: 1rem;
  }
}

@media (min-width: 1200px) {
  [type="color"], [type="date"], [type="datetime"], [type="datetime-local"], [type="email"], [type="month"], [type="number"], [type="password"], [type="search"], [type="tel"], [type="text"], [type="time"], [type="url"], [type="week"], input:not([type]), textarea {
    font-size: 1.0625rem;
  }
}

[type="color"]:focus, [type="color"]:active, [type="date"]:focus, [type="date"]:active, [type="datetime"]:focus, [type="datetime"]:active, [type="datetime-local"]:focus, [type="datetime-local"]:active, [type="email"]:focus, [type="email"]:active, [type="month"]:focus, [type="month"]:active, [type="number"]:focus, [type="number"]:active, [type="password"]:focus, [type="password"]:active, [type="search"]:focus, [type="search"]:active, [type="tel"]:focus, [type="tel"]:active, [type="text"]:focus, [type="text"]:active, [type="time"]:focus, [type="time"]:active, [type="url"]:focus, [type="url"]:active, [type="week"]:focus, [type="week"]:active, input:not([type]):focus, input:not([type]):active, textarea:focus, textarea:active {
  box-shadow: 0 0 0 2px var(--blue);
  border: none;
  outline: none;
}

.magic-label {
  --label-size: .9375rem;
  --space: 1.3125rem;
  padding-top: calc(var(--label-size) * 1.2);
  position: relative;
}

.magic-label label {
  top: calc(var(--label-size)  + var(--space));
  font-size: var(--label-size);
  transform-origin: 0;
  white-space: nowrap;
  padding: 0;
  line-height: 1;
  transition: all .2s;
  position: absolute;
  left: 1rem;
  transform: translate(0);
}

.magic-label.move-label label {
  transform: translate(calc(var(--label-size) * -1), calc((var(--space)  + var(--label-size)) * -1)) scale(.9);
  opacity: .7;
}

.magic-label:focus-within label {
  color: var(--pink);
  opacity: 1;
}

@media (min-width: 768px) {
  .magic-label {
    --space: 1.625rem;
  }
}

*, :before, :after {
  box-sizing: border-box;
}

img {
  max-width: 100%;
}

body {
  --lines: 7;
  --sp: calc(100vw / var(--lines));
  background-color: #000;
  background-image: linear-gradient(90deg, #ffffff0f 0 1px, #0000 1px 100%);
  background-size: var(--sp) 100%;
  background-position: calc(var(--sp) / 2) 0;
  position: relative;
}

@media (min-width: 990px) {
  body {
    --lines: 9;
  }
}

@media (min-width: 1200px) {
  body {
    --lines: 11;
  }
}

body, html {
  scroll-behavior: smooth;
}

html {
  scrollbar-width: thin;
  scrollbar-color: #7c197c80 #1b1b1b;
}

html::-webkit-scrollbar-track {
  background-color: #1b1b1b;
}

html::-webkit-scrollbar-thumb {
  opacity: .6;
  background-color: #7c197c80;
  border-radius: 10px;
}

html::-webkit-scrollbar-thumb:hover {
  background-color: #7c197ce6;
}

html::-webkit-scrollbar {
  width: 10px;
  display: block;
}

.mw {
  max-width: 1600px;
  position: relative;
}

@media (min-width: 1440px) {
  .mw {
    margin-left: auto;
    margin-right: auto;
  }
}

.pad {
  padding-left: 9.6%;
  padding-right: 9.6%;
}

@media (min-width: 768px) {
  .pad {
    padding-left: 7.5%;
    padding-right: 7.5%;
  }
}

@media (min-width: 1200px) {
  .pad {
    padding-left: 6.5%;
    padding-right: 6.5%;
  }
}

.starthidden {
  opacity: 0;
}

.no-js .starthidden {
  opacity: 1;
}

/*# sourceMappingURL=index.43aee54a.css.map */
