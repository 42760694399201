@use '../helpers/mixins' as *;
@use '../helpers/vars' as *;
@use 'sass:color';

.header-bg svg {
    position: absolute;
    height: auto;
    width: 100%;
    top: 0;
    overflow: visible;
    transform: translate3d(0, 0, -1px);
}
.header-bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
}

nav {
    padding-top: 50px;
    display: flex;
    justify-content: space-between;
    position: absolute;
    width: 100%;

    @include md {
        padding-top: 88px;
    }
    a {
        display: inline-block;
        position: relative;
        z-index: 1;

        &:after {
            content: '';
            width: calc(100% + 10px);
            height: 100%;
            top: 0;
            left: -5px;
            position: absolute;
            background-color: var(--purple);
            z-index: -1;
            transform-origin: left;
            transition: transform 300ms ease-out;
            transform: scaleX(0);
        }
        &:hover,
        &:focus {
            color: #fff;
            font-weight: 500;
            &:after {
                transform: scaleX(1);
            }
        }
    }
}

.computer-wrap .brought {
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 0.47em;
    font-size: size(9);
    margin-top: 3%;
    position: relative;
    z-index: 9;
    a {
        display: inline-block;
        &:hover,
        &:focus {
            svg g {
                fill: var(--blue);
            }
        }
    }
    svg {
        margin-left: 0.5rem;
        width: 66px;
        display: inline-block;
    }
}

.header .h1-wrap {
    padding-left: 6.5%;
    padding-right: 6.5%;
}

.header .caps-md {
    font-size: size(20);
    @include fluid(font-size, 320, 768, 20, 26);
    @include sm {
        @include fluid(font-size, 768, 1440, 26, 29);
    }
}
.header .caps-lg {
    font-size: size(30);
    @include fluid(font-size, 320, 768, 30, 45);
    @include sm {
        @include fluid(font-size, 768, 1440, 45, 52);
    }
}

.header h1 {
    display: grid;
    grid-template-columns: auto auto;
    text-align: center;
    grid-template-areas:
        'img img'
        'non non'
        'bs bs'
        'web site'
        'cons cons';
    img {
        grid-area: img;
        height: size(75);
        @include fluid(height, 320, 768, 75, 153);
        align-self: end;
        justify-self: center;
        margin-bottom: size(-10);
        z-index: 1;
    }
    .non {
        grid-area: non;
    }
    .bs {
        grid-area: bs;
        margin-bottom: 0.6rem;
        margin-top: 0.4rem;
        span {
            color: var(--pink);
            font-size: 60%;
            display: inline-block;
            transform: translate(-10%, -40%);
        }
    }
    .web {
        grid-area: web;
        margin-right: 15%;
    }
    .site {
        grid-area: site;
    }
    .cons {
        grid-area: cons;
        margin-top: 0.5rem;
    }

    @include sm {
        grid-template-columns: 18% 1fr max-content;
        @include fluid(max-width, 768, 1200, 630, 770);
        margin-left: auto;
        margin-right: auto;
        grid-template-areas:
            'img img img'
            'non non non'
            'bs bs bs'
            'web site cons';
        img {
            margin-bottom: -6%;
        }
        .non {
            margin-left: 10%;
        }
        .web {
            padding: 0;
            margin: 0;
            padding-right: 15%;
            text-align: left;
            align-self: start;
        }
        .site {
            margin: 0;
            text-align: left;
            align-self: end;
        }
        .cons {
            margin: 0;
        }
    }

    @include lg {
        grid-template-columns: 1fr 10% 57%;
        max-width: size(900);
        grid-template-areas:
            'img img non'
            'bs bs bs'
            'web . cons'
            '. site cons';
        .non {
            margin: 0;
        }
        .bs {
            text-align: left;
        }
        img {
            margin: 0;
        }

        .web {
            text-align: right;
            padding-right: 17%;
        }
        .site {
            padding-left: 15%;
        }
        .cons {
            padding-right: 10%;
            text-align: right;
        }
    }
}
